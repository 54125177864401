<style lang="scss" scoped>  @import "../assets/bag-label.scss";</style>
<template>
  <div class="fed-bag-label">
    <v-container class="page-break full-page-portrait pa-0 ma-0 fill-height">
      <v-row dense no-gutters>
        <v-col cols="12" class="pt-3 driver-name"> {{ driver }}</v-col>
        <v-col cols="12" class="logo py-5" style="font-size: x-large">DRIVER</v-col>
        <v-col cols="12" class="driver-name"> {{ driver }}</v-col>

      </v-row>
      <v-row no-gutters>
        <v-col>{{ bagCount }}</v-col>
        <span style="width: auto"></span>
        <v-col>{{ driverInitials }}</v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'BagDriverLabel',
  props: {
    driver: String,
    bagCount: Number
  },
  methods: {
    capitalise(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  computed: {
    driverInitials() {
      return (this.driver || '').split(' ').map(str => str.charAt(0).toUpperCase()).join('')
    }
    // bagText() {
    //   return `${this.bags || ''}`
    //     + (this.bagSize === 'large' ? ' *' : '')
    // }
  }
};
</script>
