<style lang="scss" scoped>
@import "../assets/bag-label.scss";

.circle {
  padding: 0;
  border: 4px solid black;
  height: 70px;
  border-radius: 50%;
  width: 70px;
  font-size: 50px;
  line-height: 60px;
  font-weight: bolder;
}
</style>
<template>
  <v-container fluid class="pa-0 fed-bag-label full-page-portrait ">
    <v-container class="page-break full-page-portrait ma-0 pa-0 fill-height">
      <v-row dense no-gutters>
        <v-col>
          <div class="pt-3 customer-name"> {{ customerName }}</div>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mb-0 pb-0">
        <v-col>
          <div class="">
            <img :src="qrCodeDataURL" :style="`max-width:${qrCodeSize}px; max-height:${qrCodeSize}px;`"/>
            <span class="d-print-none"><br/>{{ qrCodeValue }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mb-0 pb-0">
        <v-col>
          <img src="@/assets/FED_Logo03.svg" width="12.5%"/>
        </v-col>
      </v-row>
      <!--      <v-row>-->
      <!--        <v-col>-->
      <!--          <div class="pa-0 ma-0 customer-address" v-if="address_line_1">{{ address_line_1 }}</div>-->
      <!--          <div class="pa-0 ma-0 customer-address" v-if="address_line_2">{{ address_line_2 }}</div>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <v-row no-gutters>
        <v-col align-self="end" class="text-left pl-1">
          <div class="pa-0 ma-0 customer-address" v-if="apt">#{{ apt }}</div>
          <div class="pa-0 ma-0 customer-address" v-if="address_line_1_no_apt">{{ address_line_1_no_apt }}</div>
          <div class="pa-0 ma-0 customer-address" v-if="address_line_2">{{ address_line_2 }}</div>
          <template v-if="bins && bins.length>0">
            <v-chip outlined small v-for="(bin,i) of bins" v-bind:key="i">{{ bin }}</v-chip>
          </template>
          <span style="font-size: 15px" class="mb-4">
            {{ driverInitials }}<span v-if="sequence">-{{ sequence }}</span>
            </span>
        </v-col>
        <v-col align-self="end" cols="3" class="mr-1">
          <template v-if="`${bags}`!=='1'">
            {{ bag }} of
            <br/>
          </template>
          <div class="circle">{{ bagText }}</div>
          <span v-if="deliveryDate" style="font-size: 12px; font-family: Roboto, sans-serif">{{ deliveryDate }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>

import BagCalc from "@/store/bag-calc";
import moment from "moment";

export default {
  name: 'BagLabel',
  components: {},
  props: {
    driver: String,
    customerName: String,
    bag: {},
    bags: {},
    bagSize: String,
    bins: {},
    sequence: {},
    qrCodeValue: {},
    address_line_1: String,
    address_line_2: String,
    eta: {}
  },
  async mounted() {
    if (this.qrCodeValue) {
      this.qrCodeDataURL = await BagCalc.generateQRCodes(this.qrCodeValue, {size: this.qrCodeSize});
    }
  },
  methods: {
    capitalise(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    bagText() {
      return `${this.bags || ''}`
        + (this.bagSize === 'large' ? ' *' : '')
    },
    driverInitials() {
      return (this.driver || '').split(' ').map(str => str.charAt(0).toUpperCase()).join('')
    },
    apt() {
      const parts = this.address_line_1.split('-');
      if (parts.length > 1) {
        return parts[0];
      } else {
        return false;
      }
    },
    address_line_1_no_apt() {
      const parts = this.address_line_1.split('-');
      if (parts.length > 1) {
        return parts.splice(1).join('-');
      } else {
        return this.address_line_1;
      }
    },
    deliveryDate() {
      if (this.eta) {
        return moment(this.eta).format('D/M/YY');
      } else
        return '';
    }
  },
  data() {
    return {
      qrCodeDataURL: null,
      qrCodeSize: 100
    }
  }
};
</script>
