import { render, staticRenderFns } from "./BagLabel.vue?vue&type=template&id=28bc38d2&scoped=true&"
import script from "./BagLabel.vue?vue&type=script&lang=js&"
export * from "./BagLabel.vue?vue&type=script&lang=js&"
import style0 from "./BagLabel.vue?vue&type=style&index=0&id=28bc38d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bc38d2",
  null
  
)

export default component.exports